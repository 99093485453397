import { ElMessageBox, MessageBoxData } from "element-plus"

type AlertType = (msg: string, dangerouslyUseHTMLString?: boolean, duration?: number) => void
type FlashyType = (
  msg: string,
  type?: "success" | "error" | "warning" | "danger",
  dangerouslyUseHTMLString?: boolean,
  duration?: number
) => void
type WarnType = (
  obj: { desc: string; title: string; confirmButtonText: string; cancelButtonText?: string; redButton?: boolean },
  cb: (value: MessageBoxData) => MessageBoxData | PromiseLike<MessageBoxData>,
  catchCb: (reason: any) => void
) => void
type InfoType = (title: string, message: string, duration?: number) => void

export default {
  install(app) {
    const message = (
      message,
      type,
      dangerouslyUseHTMLString: boolean | undefined = true,
      duration: number | undefined = undefined
    ) => {
      app.config.globalProperties.$message({
        showClose: true,
        dangerouslyUseHTMLString,
        message,
        type,
        duration: duration ?? type == "error" ? 3000 : 1500,
      })
    }

    const $info: InfoType = (title, message, duration = 10000) => {
      app.config.globalProperties.$notify.info({ title, message, duration })
    }

    const $flashy: FlashyType = (msg, type = "success", dangerouslyUseHTMLString = true, duration) => {
      if (type == "danger") type = "error"
      message(msg, type, dangerouslyUseHTMLString, duration)
    }

    const $alert: AlertType = (msg, dangerouslyUseHTMLString = false, duration) => {
      message(msg, "error", dangerouslyUseHTMLString, duration)
    }

    const $warn: WarnType = (options, cb, catchCb = () => {}) => {
      return ElMessageBox.confirm(options.desc, options.title, {
        confirmButtonText: options.confirmButtonText,
        cancelButtonText: options.cancelButtonText || app.config.globalProperties.$t?.("warnPlugin"),
        type: "warning",
        confirmButtonClass: options.redButton
          ? "bg-red-500 border-red-500 hover:bg-red-400 hover:border-red-400 focus:bg-red-400 focus:border-red-400"
          : undefined,
      })
        .then(cb)
        .catch(catchCb)
    }

    app.config.globalProperties.$flashy = $flashy
    app.config.globalProperties.$alert = $alert
    app.config.globalProperties.$warn = $warn
    app.config.globalProperties.$info = $info

    // expose global functions to window as helpers
    window.App.info = $info
    window.App.flashy = $flashy
    window.App.alert = $alert
    window.App.warn = $warn

    if (window.alertMessage) {
      message(window.alertMessage, "error", false)
      window.alertMessage = undefined
    }

    if (window.successMessage) {
      message(window.successMessage, "success", false)
      window.successMessage = undefined
    }
  },
}

declare module "vue" {
  interface ComponentCustomProperties {
    $alert: AlertType
    $flashy: FlashyType
    $info: InfoType
    $warn: WarnType
  }
}

declare global {
  interface Window {
    alertMessage?: string
    successMessage?: string
    App: {
      alert: AlertType
      flashy: FlashyType
      info: InfoType
      warn: WarnType
    }
  }
}
